import React from "react";
import "./App.css";
import logo from "./logo.png"; // Replace with your actual logo path

const App = () => {
  return (
    <div className="app">
      {/* Header Section */}
      <header className="header">
        <img src={logo} alt="TidyHub Logo" className="logo" />
      </header>

      <div className="download-section">
        <h2>Download Our App</h2>
        <p>Available on both Android and iOS platforms.</p>
        <div className="store-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=com.tidyhublaundry.ae"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Get it on Google Play"
              className="store-badge"
            />
          </a>

          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"
            alt="Download on the App Store"
            className="store-badge"
          />
        </div>
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; 2024 TidyHub Laundry. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
